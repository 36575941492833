.m-teaser {
	background: #ffff;

	padding: 0;
}

.m-teaser__grid-container {
	@include grid-container(true);
}

.m-teaser__grid-item {
	@include grid-col(4);

	margin-bottom: space(4);

	text-align: center;

	@media all and (max-width: $tablet--portrait) {
		@include grid-col(12);		
	}
}

.m-teaser__item {
	@media all and (max-width: $tablet--portrait) {
		@include max-width(4);

		@include center;
	}
}

.m-teaser__headline {
	@include typo--h3;
}

.m-teaser__text {
	@include mod_text;

	p {
		margin-bottom: space(1);
	}
}

.m-teaser__link {
	@include font-weight-bold;
	
	display: inline-block;
	border-bottom: 1px solid;

	margin-top: space(2);
}

.m-teaser__image {
	margin-bottom: space(2);

	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;

	max-width: rem(200);

	margin-left: auto;
	margin-right: auto;
}