@mixin mod_text {
    @include typo--copy;

    h2 {
        @include typo--h2;

        &:not(:first-child) {
            padding-top: space(2);
        }
    }

    h3 {
        @include typo--h3;
    }

    p {
        margin-bottom: space(2);

        &:last-child {
            margin-bottom: 0;
        }

        a {
            text-decoration: none;
            border-bottom: 1px solid;
        }
    }

    ul {
        margin-bottom: space(2);

        @media all and (min-width: $post-mobile) {
            margin-bottom: space(2);
        }

        &:last-child {
            margin-bottom: 0;
        }

        li {
            position: relative;
            padding-left: space(1);
            list-style: none;
            display: list-item;

            &:before {
                content: '•';
                position: absolute;
                top: 1px;
                left: 0;
                color: $base--color--main--1;
            }
        }
    }

    ol {
        padding-left: space(2);

        margin-bottom: space(2);

        &:last-child {
            margin-bottom: 0;
        }

        li {
            position: relative;
            list-style: decimal;
            display: list-item;
            padding-left: space(1);
        }
    }
}

.m-text {
   @include mod_text();
}

.m-text--centered {
    text-align: center;
}