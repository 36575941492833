html,
body {
    position: relative;
    width: 100%;

    background-color: #fff;
    -webkit-overflow-scrolling: touch;
}

/*

Sticky Footer Solution based on:

https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/

Note: See "View the full source" in the blog entry to see the following source code:

https://github.com/philipwalton/solved-by-flexbox/blob/master/assets/css/components/site.css

*/

/**
* 1. Avoid the IE 10-11 `min-height` bug.
* 2. Set `flex-shrink` to `0` to prevent some browsers from
*    letting these items shrink to smaller than their content's default
*    minimum size. See http://bit.ly/1Mn35US for details.
* 3. Use `%` instead of `vh` since `vh` is buggy in older mobile Safari.
*/

.layout__page-wrapper {
    @include clearfix;

    display: flex;
    flex-direction: column;
    height: 100%; /* 1, 3 */

    min-height: 100vh;

    > *:not(.layout__main-wrapper) {
        flex: none; /* 2 */
    }
}

.layout__main-wrapper {
    flex: 1 0 auto; /* 2 */
    width: 100%;
}

/* Further Sticky Footer Adjustments */

#mm-1 {
    height: 100%;
}