@mixin hr {
	position: relative;
	border: 0;
	height: 0;
	border-bottom: 1px solid $base--color--gray--3;

	&:before {
		content: '';
		display: block;
		width: 200vw;
		border-bottom: 1px solid $base--color--gray--3;

		position: absolute;
		left: -100vh;
	}
}

.m-hr {
	@include hr;

	margin: space(3) 0;
}