.mod_content {
	@include center;

	background-color: #fff;

	max-width: rem(1280);

	padding: space(4);

	margin-bottom: space(4);

	overflow: hidden;

	@media all and (max-width: $tablet--landscape) {
		padding: space(4) space(2) space(2);
	}

	@media all and (max-width: $mobile) {
		padding: space(4) space(2) space(2);

		margin-bottom: 0;
	}

	.m-container {
		padding-left: 0;
		padding-right: 0;
	}
}

.mod_content--has-sidebar {
	@media all and (min-width: $post-tablet--landscape) {
		display: flex;
		
		.mod_content__sidebar {
			flex: 0 0 rem(280);
		}

		.mod_content__main {
			flex: 1 1 auto;

			padding-left: space(2);
		}
	}
}


.m-content__bottom-link {
	text-align: center;

	margin-bottom: space(4);

	a {
		text-transform: uppercase;
		padding-bottom: space(2);
		border-bottom: 5px solid #000;
	}
}

.mod_content__sidebar {
	@media all and (max-width: $tablet--landscape) {
		margin-bottom: space(2);
	}
}

.mod_content__sidebar-logo {
	width: rem(120);

	margin: 0 auto;

	display: block;

	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;

	margin-bottom: space(3);
	
	// Quickfix: The A&C Logo does somehow overlap at the bottom. The border triggers correct rendering.
	border: 1px solid transparent;

	&:after {
	    display: block;
	    content: '';

	    background-size: contain;
	    background-repeat: no-repeat;
	    background-position: center center;

	    padding-top: calc(100% / (180/110));
	}
}

.mod_content__sidebar-logo--pliester {
	&:after {
		background-image: url('./../assets/images/logo--pliester.svg');
	}
}

.mod_content__sidebar-logo--hacotex {
	&:after {
		background-image: url('./../assets/images/logo--hacotext.svg');
	}
}

.mod_content__sidebar-logo--ac {
	&:after {
		background-image: url('./../assets/images/logo--ac.svg');
	}
}
