.m-map {
	
	&__map-container {
		position: relative;

		&:after {
		    display: block;
		    content: '';
		    padding-top: (100% / (2/1));
		}

		@media all and (max-width: $tablet--portrait) {
			&:after {
			    padding-top: (100% / (1/1));
			}
		}

		@media all and (max-width: $mobile) {
			&:after {
			    padding-top: (100% / (1/1.5));
			}
		}
	}

	&__map {
		position: absolute !important;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;

		height: 100%;
		width: 100%;

		.si-close-button {
			width: space(3);
			height: space(3);
		}
	}

	&__center-button {
		position: absolute;
		
		top: space(2);
		right: space(4);

		opacity: 0;
		transition: opacity 0.3s ease-in-out;

		&.is-in {
			opacity: 1;

			transition: opacity 0.3s ease-in-out;
		}

		@media all and (max-width: $tablet--portrait) {
			top: auto;
			right: auto;

			left: space(1);
			bottom: space(2);
		}
	}

	&__nav {
		position: absolute;

		left: space(2);
		bottom: space(2);

		display: flex;

		@media all and (max-width: $tablet--portrait) {
			bottom: rem(70);

			left: space(1);
		}

		button {
			margin-right: space(1);

			background: $base--color--gray--2;
			border: 1px solid $base--color--gray--2;

			&.is-active {
				background: $base--color--main--1;
				border: 1px solid $base--color--main--1;
			}

			&:last-child {
				display: none;
			}
		}
	}

	&__legend {
		position: absolute;

		right: space(4);
		bottom: space(2);

		z-index: 99999;

		background-color: $base--color--main--2;

		display: flex;

		padding: space(1);

		@media all and (max-width: $tablet--portrait) {
			font-size: rem(10);
			left: space(1);
			right: auto;
		}

		> *:first-child,
		> *:last-child {
			&:before {
				display: inline-block;

				content: '';
				width: rem(10);
				height: rem(10);
				background-color: $base--color--main--1;

				border-radius: 100%;
			}
		}

		> *:first-child {
			margin-right: space(1);
		}

		> *:last-child {
			&:before {
				background-color: red;
			}
		}
	}

	&__footer {
		display: flex;
		flex-wrap: wrap;
	}

	&__elem {
		padding-right: space(4);

		@media all and (max-width: $tablet--landscape) {
			order: 2;

			margin-bottom: space(2);

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__elem-headline {
		margin-bottom: space(1);

		.page--cantinetta & {
			display: none;
		}
	}

	&__link {
		margin-left: auto;

		@media all and (max-width: $tablet--landscape) {
			width: 100%;
			order: 1;

			margin-bottom: space(3);
		}
	}
}


/* Map Popup Content */

.m-map-popup-content {
	//padding: space(4);
}

.m-map-popup-content__name {
	margin-bottom: space(2);
}

.m-map-popup-content__address {
	margin-bottom: space(2);
}

.m-map-popup-content__link {
	text-decoration: underline;
	cursor: pointer;
}