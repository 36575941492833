/**
 * Spinner
 */

@mixin spinner($target: before, $color: false) {
    &:#{$target} {
        content: '';
        position: absolute;

        top: 50%;
        left: 50%;

        width: rem(40);
        height: rem(40);

        margin-top: rem(-20);
        margin-left: rem(-20);

        border-radius: 100%;

        @if ($color != false) {
            background-color: $color;
        } @else {
            background-color: $base--color--gray--1;
        }

        animation: spinner-scaleout 0.8s infinite ease-in-out;
    }
}

@keyframes spinner-scaleout {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}


/**
 * Image Hover Effekt
 */

@mixin image-hover-effect() {
    // img {
    //     transition: transform 0.3s ease-in-out;
    // }

    .m-arrow-link {
        &:after {
            transition: transform 0.3s ease-in-out;
            transform: translateX(0px);
        }
    }

    &:hover {
        // img {
        //     transform: scale(1.04);
        //     transition: transform 0.5s ease-out;
        // }

        .m-arrow-link {
            &:after {
                transition: transform 0.5s ease-in-out;
                transform: translateX(3px);
            }
        }
    }
}

/**
 * Clearfix
 */

@mixin clearfix {
    &:before,
    &:after {
        content: ' ';
        display: table;
        clear: both;
    } 
}

/**
* Disable text selection
*/

@mixin disable-selection {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/**
* Make container scrollable
*/

@mixin scrollable() {
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

/** Center Elements **/

@mixin center() {
    margin-left: auto;
    margin-right: auto;
}