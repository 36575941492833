.m-example__grid {
	display: grid;

	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: rem($base--grid-gutter);
	grid-row-gap: rem($base--grid-gutter);

	@media all and (max-width: $phablet) {
		grid-template-columns: 1fr 1fr;

		grid-column-gap: rem($base--grid-gutter / 2);
		grid-row-gap: rem($base--grid-gutter / 2);
	}

	@media all and (max-width: 450px) {
		grid-template-columns: 1fr;
	}
}


// Hide all examples initially
.m-example {
	display: none;
	max-width: 300px;
	// max-width: 100%;
}

@media screen and (max-width: 450px) {
	.m-example {
		position: relative;
		transform: translateX(-50%);
		left: 50%;
	}
}

.m-example__button > * {
	pointer-events: none;
	cursor: pointer;
  }

.m-example__image {
	position: relative;

	border: 1px solid $base--color--gray--1;
	
	margin-bottom: space(1);

	background-color: #F9F9F9;

	img {
		position: absolute;

		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		
		// opacity: 0;

		&.lazyloaded {
			transition: opacity 0.3s ease-in-out;
			opacity: 1;
		}
	}
}

.m-example__title {
	margin-bottom: space(1);
}

@media screen and (max-width: 500px) {
	.m-example__title {
		font-size: 14px;
	}
}

.m-example__like-button {
	padding: 5px;
	text-align: center;

	background-color: $base--color--gray--4;

	cursor: pointer;

	.is-fav & {
		background-color: green;
		color: #fff;
	}
}

.m-example__like-button--remove {
	background-color: #000;
	color: #fff;
}

.m-example-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-bottom: 40px;
}

.m-example__button,
.m-favorites__button {
	padding: 6px 9px;

	border: 1px solid #000;

	color: #333;
	font-family: "Open Sans";
	font-size: 14px;
	text-transform: uppercase;
	line-height: 19px; 

	margin-bottom: 0.75rem;

	display: inline-flex;
    align-items: center;

    img {
        width: 14px;
        height: 14px;

        padding: 0;
        margin-right: 5px;
    }
}

.m-example__button--remove {
	padding: 6px 13px;

	border: 1px solid #fff;
	background-color: #000;

	color: #fff;
}

.mod_products-note {
	padding: 45px;
	background-color: $base--color--gray--4;
	text-align: center;

	display: none;

	.is-logged-in & {
		display: block;
	}
}