/**

Generell:

Jedes Content Element umfasst immer einen Container und bekommt einen modifier für die Breite, sowie für den Abstand nach unten 

	<div class="m-container m-container--small m-container-mb" data-ce="name">
		<div class="m-module-name">
		 ...
		</div>
	</div>

Ein Modul wird dann in einen Container eingebunden. Die Breite des Module bestimmt also der Container.

Weiterhin bekommt ein Container immer einen fest definierten Abstand nach unten. Dieser kann sein:

.m-container--mb - Default Abstand
.m-container--mb--small - Kleiner Abstand

/*

Parameter: $size
--------------------

Those sizes are defined by the design. If you need more or less talk to the design

*/

@mixin container($size) {
    margin-left: auto; 
    margin-right: auto; 

    box-sizing: content-box;

    padding-left: space(4);
    padding-right: space(4);

    @media all and (max-width: $tablet--portrait) {
    	padding-left: space(3);
    	padding-right: space(3);
    }

    @media all and (max-width: $mobile) {
    	padding-left: space(2);
    	padding-right: space(2);
    }

	@if ($size == 'large') {
		@include max-width(12);
	}

	@if ($size == 'medium') {
		@include max-width(8);
	}

	@if ($size == 'small') {
		@include max-width(6);
	}
}

// Default Abstand von einem CE nach unten
@mixin container-mb {
	margin-bottom: space(4);

	@media all and (max-width: $tablet--landscape) {
		margin-bottom: space(3);
	}
}

// Kleiner Abstand von einem CE nach unten
@mixin container-mb--small {
	margin-bottom: space(2);

	@media all and (max-width: $phablet) {
		margin-bottom: space(1);
	}
}

// Setzt die Eigenschaften eines Containers zurück, wenn dieser in einem Akkordion verwendet wird.
@mixin reset-container() {
	padding-left: 0;
	padding-right: 0;
	max-width: auto;
	margin-bottom: 0;
}

/**

Klassen

**/

.m-container {
	margin-left: auto;	
	margin-right: auto;
}

/* Spezial Klasse für CE Text, welches man optional nach links ausrichten kann */
.m-container--left {
	margin-left: 0 !important;

	.m-container__headline {
		text-align: left;
	}
}

.m-container--large {
	@include container(large);
}

.m-container--medium {
	@include container(medium);
}

.m-container--small {
	@include container(small);
}

.m-container--mb {
	@include container-mb;
}

.m-container--mb--small {
	@include container-mb--small;
}