.m-header {
    position: relative;

    top: 0;
    right: 0;
    left: 0;

    padding: space(1) space(1) 0;

    background-color: #fff;

    backface-visibility: hidden;

    text-transform: uppercase;

    font-size: 15px;

    box-shadow: 0 0 10px 2px #efefef;

    @media all and (max-width: $phablet) {
        padding: space(1) space(1) 0;
    }
}

.m-header__container {
    @include max-width(12);

    margin: 0 auto;

    position: relative;

    padding: 0;

    @media all and (max-width: $breakpoint--nav) {
        overflow: hidden;   
    }
}

.m-header__top {
    display: flex;
    justify-content: space-between;

    margin-bottom: space(1);
}

.m-header__lang-switcher {
    li {
        float: left;

        a {
            color: $base--color--gray--1;
        }

        &:before {
            display: inline;
            content: '|';

            margin-left: rem(8);
            margin-right: rem(4);

            position: relative;
            top: rem(-1);

            color: $base--color--gray--1;
        }
        
        &:first-child {
            &:before {
                display: none;
            }
        }

        &.is-active {
            a {
                @include font-weight-bold;
            }
        }
    }
}

.m-header__right {
    display: flex;
}

.m-header__search {
    &:before {
        display: inline;
        content: url('../assets/images/icon--loupe.svg');
        position: relative;
        top: 2px;
        margin-right: space(1);
    }
}

.m-header__divider {
    &:after {
        display: inline;
        content: '|';

        margin-left: rem(2);
        margin-right: rem(12);
    }
}

.m-header__login {
    &:before {
        display: inline;
        content: url('../assets/images/icon--user.svg');
        position: relative;
        top: 1px;
        margin-right: space(1);
    }
}

.m-header__logo {
    display: block;
    margin: 0 auto;

    max-width: 180px;

    margin-bottom: space(3);

    background-image: url('../assets/images/logo--escher.svg');
    background-size: contain;
    background-repeat: no-repeat;

    &:after {
        display: block;
        content: '';
        padding-top: calc(100% / (170/108));
    }
}

.m-header__nav {
    display: flex;

    justify-content: space-around;

    > li {
        position: relative;

        > a {
        	display: inline-block;
        	padding: space(2) 0;

        	&:hover {
        		color: $base--color--main--1;
        	}
        }

        &:before {
            display: block;
            content: '';
            
            opacity: 0;
            transition: opacity 0.3s ease-in-out;
        }

        &.is-active,
        &:hover {
        	> a {
        		color: $base--color--main--1;
        	}

        	&:before {
        		display: block;
        		content: '';

        		position: absolute;

        		right: 0;
        		bottom: 0;
        		left: 0;

        		height: 5px;
        		background-color: #000;

                opacity: 1;
                transition: opacity 0.3s ease-in-out;
        	}
        }

        &.has-dropdown {
            > a:after {
                display: inline-block;
                content: url('../assets/images/icon--arrow--down.svg');

                position: relative;
                top: -1px;

                margin-left: space(1);
            }

            &:hover {
                &:before {
                    opacity: 0 !important;
                    transition: opacity 0.3s ease-in-out;
                }

                ul {
                    transition: opacity 0.3s ease-in-out;
                    opacity: 1 !important;
                    pointer-events: auto;       
                }
            }
        }

        ul {
            transition: opacity 0.3s ease-in-out;
            opacity: 0;
        }
    }

    ul {
        position: absolute;
        top: 100%;

        background-color: #fff;
        border-top: 1px solid $base--color--gray--3;
        border-right: 1px solid $base--color--gray--3;
        border-bottom: 1px solid $base--color--gray--3;
        border-left: 1px solid $base--color--gray--3;

        z-index: 1111;

        display: flex;

        opacity: 0;
        pointer-events: none;

        li {

            background-color: #fff;

            padding: space(2) space(1);

            &:first-child {
                padding-left: space(2);
            }

            &:last-child {
                padding-right: space(2);
            }

            a {
                display: block;

                &:hover {
                    .m-header__nav-icon {
                        display: none;
                    }

                    .m-header__nav-icon--hover {
                        display: block;
                    }
                }
            }

            .m-header__nav-icon {
                display: block;
                background-color: #fff;

                width: rem(80);

                text-indent: -9999px;

                background-size: contain;
                background-repeat: no-repeat;
                background-position: center bottom;

                &:after {
                    display: block;
                    content: '';

                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center center;

                    padding-top: calc(100% / (180/110));
                }
            }

            .m-header__nav-icon--hover {
                display: none;
            }
        }
    }
}

.m-header__nav-trigger {
    margin-left: auto;

    display: none;

    text-transform: uppercase;

    &:after {
        display: inline-block;
        top: 0;
        right: rem(0);
        content: '';
        background-image: url('../assets/images/icon--hamburger.svg');
        background-size: contain;
        background-repeat: no-repeat;
        width: rem(25);
        height: rem(18);
        position: relative;
        top: rem(4);

        margin-left: space(7);
    }

    .mm-wrapper_opened & {
    	&:after {
    		background-image: url('../assets/images/icon--close.svg');
    	}
    }
}

@media all and (max-width: $breakpoint--nav) {
	.m-header__lang-switcher,
	.m-header__right,
	.m-header__nav {
		display: none;
	}

	.m-header__nav-trigger {
		display: block;
	}
}

.m-header--offcanvasnav {
    display: none;

    li.is-active {
        a {
            @include font-weight-bold;
        }       
    }
    
    .mm-menu_opened & {
        display: block;
    }
}

.m-header__search-form {
    cursor: pointer;

    position: relative;
    top: -1px;

    input {
        @include font(text);

        font-size: em(16);
        display: block;

        width: 100%;
        background-color: #fff;
        margin: 0;
        border-radius: 0;

        cursor: pointer;
        display: inline;
        position: relative;
        top: 1px;
        border: none;
        // text-transform: uppercase;
        width: 55px;
        transition: width 0.3s ease-in-out;
        outline: none;

        border-bottom: 1px solid transparent;

        &:focus {
            width: 300px;
            transition: width 0.3s ease-in-out;
            border-bottom: 1px solid $base--color--gray--2;
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: $base--color--main--1;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: $base--color--main--1;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: $base--color--main--1;
    }

    &:before {
        display: inline;
        content: url('../assets/images/icon--loupe.svg');
        position: relative;
        top: 3px;
        margin-right: 6px;
    }
}

.m-header__favorites-link {
    cursor: pointer;

    display: none;
    
    .is-logged-in & {
        display: block;
    }
    
    &:before {
        display: inline;
        content: '•';

        margin-left: rem(12);
        margin-right: rem(12);
    }
}