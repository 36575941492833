.m-sidenav {
	// hide all sublevels initiall

	font-size: rem(14);

	ul {
		display: none;
	}

	a.is-active {
		font-weight: bold;
	}
	
	li.is-active {
		> a {
			font-weight: bold;
		}
	}

	// show sublevel if active

	li.has-children.is-active {
		> a {
			font-weight: bold;
			border-bottom: none;
		}

		> ul {
			display: block;
		}

		padding-bottom: space(1);
	}

	// First Level

	> li {
		> a {
			display: block;
			padding: space(1) space(1, true);

			border-top: 1px solid $base--color--gray--2;
		}

		&:last-child {
			> a {
				border-bottom: 1px solid $base--color--gray--2;
			}
		}
	}

	// Second Level

	> li > ul {
		margin-left: space(1);

		> li > a {
			display: block;

			padding: space(1, true);
		}
	}

	// Third Level

	> li > ul > li > ul {
		margin-left: space(1);

		> li > a {
			display: block;

			padding: space(1, true);

			font-size: rem(14);
		}
	}

	// has children

	li.has-children > a {
		position: relative;

		padding-right: space(1);

		&:after {
			// display: block;
			// content: '';
			// background-image: url('../assets/images/icon--arrow--right.svg');
			// background-size: contain;
			// background-repeat: no-repeat;
			// background-position: center center;
			// top: 1px;
			// right: 0;
			// position: absolute;
			// width: 15px;
			// height: 15px;

			// top: 50%;
			// transform: translateY(-50%);
		}
	}

	li.has-children.is-active {
		padding-bottom: 0.25rem;

		& > a {
			padding-bottom: 0.5rem;

			&:after {
				// transform: translateY(-50%) rotate(90deg);
			}
		}
	}

	a:hover {
		color: $base--color--gray--1;
	}
}