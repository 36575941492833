/* Pass pixel to get them as em returned */
@function em($px) {
    @return $px / $base--font-size--unitless + em;
}

/* Pass pixel to get them as rem returned */
@function rem($px) {
    @return $px / $base--font-size--unitless + rem;
}


/**

Parameter: $size
-------------------------

Return the size according to the $base--spacer map within `variables.scss`.

Example:

space(1) -> 12
space(2) -> 24
space(3) -> 42

..

Parameter: $half
-------------------------

If $half is true half of the spacer size is used. This is needed when a space via padding
between to elements with a specific size is needed. For example:

ul li a {
	padding: space(1, true);
}

Parameter: $cosmetics
-------------------------

Pass some additional pixel. Currently used to vertically position the text within mod button.

*/

@function space($size, $half: false, $cosmetics: 0) {
	@if ($half != false) {
	    @return ((map-get($base--spacer, $size) +  $cosmetics) / 2) / $base--font-size--unitless + rem;	
	} @else {
	    @return (map-get($base--spacer, $size) + $cosmetics) / $base--font-size--unitless + rem;	
	}
}