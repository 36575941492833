/*

Grid:

There are no grid classes. The grid is always coupled to the modules markup.

Example:

To get a 50/50 grid:

<div class="mod_module__grid-container">
    <div class="mod_module__grid-element">
        <div class="mod_module__element"></div>
    </div>
    <div class="mod_module__grid-element">
        <div class="mod_module__element"></div>
    </div>
</div>

.mod_module__grid-container {
    @include grid-container;   
}

.mod_module__grid-element {
    @include grid-col(6);
}

/*

$double: if true, the grid gutter gets doubled

*/
@mixin grid-container($double: false) {
    display: flex;
    flex-wrap: wrap;

    $base--grid-gutter: $base--grid-gutter; 

    @if ($double != false) {
        $base--grid-gutter: $base--grid-gutter * 2;
    }

    margin-left: rem(-$base--grid-gutter/2);
    margin-right: rem(-$base--grid-gutter/2);

    @media all and (max-width: $mobile) {
    	padding-left: rem($base--grid-gutter/4);
    	padding-right: rem($base--grid-gutter/4);
    }
}

/*

$cols: Number of cols to span width
$double: if true, the grid gutter gets doubled

*/
@mixin grid-col($cols, $double: false) {

    $base--grid-gutter: $base--grid-gutter; 

    @if ($double != false) {
        $base--grid-gutter: $base--grid-gutter * 2;
    }

    padding-left: rem($base--grid-gutter/2);
    padding-right: rem($base--grid-gutter/2);

    width: ($cols / 12) * 100%;

    @media all and (max-width: $mobile) {
    	padding-left: rem($base--grid-gutter--mobile/2);
    	padding-right: rem($base--grid-gutter--mobile/2);
    }
}

/* Sets a max with for an element with the size of the named cols */
@mixin max-width($cols) {
	max-width: rem(($cols / 12) * $base--grid--max-page-width);
}

/* Sets a with for an element with the size of the named cols */
@mixin width($cols) {
    width: rem(($cols / 12) * $base--grid--max-page-width);
}

/* Returns the width of a col in percentage based on the passed amount of cols */
@function span($cols) {
    @return ($cols / 12) * 100%;
}

/* Return the gutter. Pass a $count value to get the gutter multiplied */
@function gutter($count) {
    @return rem($base--grid-gutter * $count);
}