%inputstyle {
    @include font(text);

    font-size: em(16);
    display: block;

    border: none;
    border: 1px solid;

    width: 100%;
    background-color: #fff;
    margin: 0;
    border-radius: 0;

    padding: space(1);
}

.mod_form {
    textarea,
    input[type="text"],
    input[type="email"],
    input[type="password"],
    input[type="submit"],
    input[type="number"],
    input[type="tel"],
    input[type="url"],
    input[type="date"],
    select {
        @extend %inputstyle;

        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    textarea {
        &.mod_form__textarea--height-2-rows {
            min-height: em(95);
        }

        &.mod_form__textarea--height-3-rows {
            min-height: em(147);
        }
    }


    // Fieldset styling
    // Check: http://thatemil.com/blog/2015/01/03/reset-your-fieldset/
    //
    // As default the fieldset has no style. it can be styled via the mod_form__fieldset-box class.
    // Styled fieldsets can be nested up to two times.
    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
        min-width: 0;

        margin-bottom: space(2);

        &:last-child {
            margin-bottom: 0;
        }

        legend {
            display: none;
        }

        &.mod_form__fieldset-box {
            border: 1px solid $base--color--gray--4;
            background-color: $base--color--gray--4;

            // Nested style
            .mod_form__fieldset-box {
                border: 1px solid $base--color--gray--4;
                background-color: $base--color--gray--1;
            }
        }
    }

    label {
        cursor: pointer;
    }

    a {
        text-decoration: underline;

        &:hover {
            text-decoration: none;
        }
    }
}

/**
 * Placeholder Color
 */

::-webkit-input-placeholder {
    color: $base--color--main--1;
}

:-moz-placeholder { /* Firefox 18- */
    color: $base--color--main--1;
}

::-moz-placeholder {  /* Firefox 19+ */
    color: $base--color--main--1;
}

:-ms-input-placeholder {
    color: $base--color--main--1;
}

/**
 * Input Row
 */

.mod_form__row {
    @include clearfix;

    position: relative;

    margin-bottom: space(2);

    &:last-child {
        margin-bottom: 0;
    }

    label {
        margin-bottom: space(1);

        display: none;
    }
}

.mod_form__row__info {
    margin-top: em(4);
}

/**
 * Custom Select Style
 */

// @TODO
// Make select icon field clickable

.mod_form__select {
    @extend %inputstyle;

    // @include icon(select, before);

    position: relative;

    // overwrite %inputstyle to make visual select element clickable
    padding: 0;

    // overwrite %inputstyle to make the field auto width instead of 100%
    display: inline-block;
    width: auto;

    @media all and (max-width: $mobile) {
        display: block;
    }

    select {
        font-size: em(14);

        color: $base--color--main--1;

        display: block;
        width: 100%;

        // add padding to select to make it clickable
        padding-right: em(40 + strip-units($base--font-size));

        // select on top to make it clickable
        position: relative;
        z-index: 100;

        border: 0;
        box-shadow: none;

        background-color: transparent;
        background-image: none;

        cursor: pointer;

        &:focus {
            outline: none;
        }
    }

    &:before {
        color: $base--color--main--1;
        position: absolute;
        top: em(13);
        right: em(16);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: em(40);
        border-left: 1px solid $base--color--main--1;
    }
}

/**
 * Radio and Checkbox Groups
 */

.mod_form__group {
    @include clearfix;

    margin-bottom: space(1);

    &:last-child {
        margin-bottom: 0;
    }
}

.mod_form__group__row {
    @include clearfix;

    position: relative;

    margin-bottom: space(1);

    &:last-child {
        margin-bottom: 0;
    }

    input[type='radio'],
    input[type='checkbox'] {

        // hide original element
        position: absolute;
        left: -9999px;

        // checked style
        &:checked {
            + label {
                &:after {
                    position: absolute;
                    content: '';
                    top: em(8);
                    left: em(3);

                    width: em(6);
                    height: em(6);
                    background-color: red;
                }
            }
        }
    }

    label {
        display: block;
        position: relative;
        padding-left: em(20);

        // shared custom checkbox/radio style
        &:before {
            position: absolute;
            content: '';
            left: 0;
            top: em(5);
            display: inline-block;
            width: em(12);
            height: em(12);
            border: 1px solid $base--color--gray--4;
            background-color: #fff;
            margin-right: space(1);
        }
    }

    // custom checkbox style
    input[type='checkbox'] {
        + label {
            &:before {
                background-color: #fff;
            }
        }
    }

    // custom radio style
    input[type='radio'] {
        + label {
            &:before,
            &:after {
                border-radius: 100%;
            }
        }
    }
}

/**
 * Amount
 */

.mod_form__input-amount {
    @include clearfix;
    @include disable-selection;

    input[type="number"] {
        padding: 0;
        text-align: center;
        border: 0;
        -moz-appearance: textfield;

        @media all and (max-width: $mobile) {
            padding: space(1);
        }
    }

    // Remove native plus/minus
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0;
    }

    background-color: #fff;
    padding: space(1);
    width: em(70);

    @media all and (max-width: $mobile) {
        width: auto;
        max-width: em(200);
        margin-top: space(1);
    }

    /**
     * Special styling within the sum table
     *
     * "The art of fighting display: inline-block.."
     *
     * We don't want to use display: inline-block to adjust the element with text-align: center
     * since it produce a slight margin bottom. Therefore we block it and use margin: 0 auto.
     */

    .mod_table--sum & {
        margin: 0 auto;

        @media all and (max-width: $mobile) {
            margin: 0;
        }
    }
}

.mod_form__input-amount__number {
    float: left;

    color: $base--color--main--1;
    text-align: center;

    width: 50%;
}

.mod_form__input-amount__minus,
.mod_form__input-amount__plus {
    float: left;

    width: 25%;

    text-align: center;
    cursor: pointer;

    @media all and (max-width: $mobile) {
        padding: space(1);
    }
}

// @TODO temporary
.mod_form__input-amount__minus {
    padding-left: 6px;
}

/**
 * Submit
 */

.mod_form__submit {
    @include clearfix;

    display: block;

    input {
        background-color: #000 !important;
        color: #fff;
        cursor: pointer;
    }
}

.submit {
    background-color: #000 !important;
    color: #fff;
    cursor: pointer;

    display: inline-block;

    padding: 10px;
}

/**
 * Datepicker field
 */

.mod_form__datepicker {
    + .picker {
        display: none;
        position: absolute;
        left: 0;

        background-color: #fff;
        padding: space(1);

        z-index: 10;

        border: 1px solid $base--color--gray--2;

        &.picker--opened {
            display: block;
        }
    }
}

/**
 * Voucher Field
 */

@mixin inline-form {
    @include clearfix;

    display: inline-block;

    input {
        width: initial;
        float: left;
        border: 0;
    }

    input[type="search"],
    input[type="text"] {
        width: 60%;
    }

    input[type="submit"] {
        padding: space(1) space(1) space(1) space(1);

        background-color: $base--color--gray--2;

        cursor: pointer;

        width: 40%;
    }
}

// @TODO Remove this class in exchange for .mod_form--inline if possible.
.mod_form--voucher {
    @include inline-form;
}

// @TODO Move into mod_search or move mod_search into form.
.mod_form--search {
    @include inline-form;

    display: block;

    form {
        display: flex;

        input[type="text"] {
            width: 100%;
        }

        input[type="submit"] {
            flex: 1;
            width: 100%;
        }
    }
}

/**
 * Required Note
 */

.mod_form__required-note {
    color: $base--color--main--1;
}

/**
 * Form Error
 */

.mod_form__error {
	font-size: rem(14);

    color: red;

    margin-top: em(4);
}

/**
 * Form Grid
 */

div[class^="mod_form__row__elem"] {
    position: relative;
    float: left;

    padding-right: space(1);

    &:last-child {
        padding-right: 0;
    }

    @media all and (max-width: $mobile) {
        padding-right: 0;

        width: 100%;

        margin-bottom: space(1);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.mod_form__row__elem-25 {
    width: 25%;
}

.mod_form__row__elem-50 {
    width: 50%;
}

.mod_form__row__elem-75 {
    width: 75%;
}

/**
 * Form Accordion
 */

.mod_form__accordion {
    @include clearfix;
}

.mod_form__accordion__trigger {
    cursor: pointer;
}

.mod_form__accordion__content {
    margin-top: space(1);

    display: none;
}

.mod_form__accordion__content--initial-open {
    display: block;
}

/**
 * animated labels style
 */

.mod_form__field-container {
    position: relative;

    input {
        transition: 0.1s all linear;

        height: em(42);
    }

    label {
        display: block;

        position: absolute;
        top: em(9);
        left: space(1);

        font-size: em(16);
        color: $base--color--main--1;
        cursor: text;

        transition: 0.1s all linear;
    }

    &.active {
        input {
            padding: rem(12) space(1) 0;
        }

        label {
            top: rem(3);
            font-size: em(9);

            color: $base--color--main--1;
        }
    }
}

.m-form__note {
    position: relative;
    // left: 6px; needs to be there because the * displays not in the normal text flow, but 6px to the left
    left: 6px;

    color: #333333;
    font-family: "Open Sans";
    font-size: 10px;
    line-height: 14px;

    margin-right: auto;
    margin-bottom: 30px;
}