.m-intro {
	position: relative;

	&:after {
		display: block;
		content: '';
		padding-top: calc(100% / (1440/550));

    @media all and (max-width: $phablet) {
      padding-top: calc(100% / (1/1));
    }

	}
}

.m-intro__text {
	font-size: rem(48);

	line-height: 56px;

	font-weight: 300;

	color: #fff;

  text-align: center;

  padding: 0 20px;

	@include max-width(12);

  @media all and (max-width: $tablet--landscape) {
    font-size: rem(30);

    line-height: 34px;
  }
}

.m-intro--black-font {
	.m-intro__text {
		color: #000;
	}
}

.m-intro__slide {
  position: absolute;

  left: 0px;
  top: 0px;

  width: 100%;
  height: 100%;

  opacity: 0;
  z-index: 1;

  transition: opacity 2s;

  display: flex;
  justify-content: center;
  align-items: center;

  background-size: cover;
  background-position: center center;

  &.is-in {
  	opacity: 1;
  	z-index: 2;
  }
}