@import 'mod_form';

* {
    box-sizing: border-box;
}

// These classes are the divs that wrap the different popup-windows in each other
.m-favourites,
.m-favourites--before-login,
.m-sample-card,
.m-favourites--empty,
.m-shared-list-popup--before-login,
.m-shared-list-popup {
    position: fixed;

    display: none;

    overflow: scroll;

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    background-color: rgba(51,51,51,0.75);
    z-index: 5;
}

.m-favourites__popup {
    position: relative;
    top: 80px;

    display: block;

    min-height: 50vh;
    max-width: 1200px;

    text-align: center;
    text-transform: none;

    margin: auto;
    margin-bottom: 160px;

    border: 1px solid black;
    background-color: #fff;

    z-index: 3;
}

@media screen and (max-width: 1350px) {
    .m-favourites__popup {
        top: 24px;
        margin-bottom: 80px;

        max-width: 90%;
    }
}

.m-requested-sample-card {
    min-height: 700px;

    border: 1px solid #000;
    margin: auto;

    text-align: center;
}

@media screen and (max-width: 500px) {
    .m-requested-sample-card {
        height: 550px;
    }
}

// General Styles for elements which are in almost every popup window

.m-favourites__headline {
    margin-top: 60px;
    margin-bottom: 50px;

    font-size: 28px;
    line-height: 38px;
    font-family: "Open Sans";
    font-weight: bold;

    color: #333;

    text-align: center;
}

.m-favourites__rectangle {
    box-sizing: border-box;

    height: 1.5px;
    max-width: 150px;

    border: 1px solid #333333;
    background-color: #D8D8D8;

    margin-bottom: 50px;
}

.m-favourites__text {
    @include favourites--text;

    text-align: center;

    max-width: 785px;
    margin: auto;

    margin-bottom: 50px;
}

@media screen and (max-width: 1250px) {
    .m-favourites__headline {
        font-size: 24px;
        line-height: 32px;
    }
    
    .m-favourites__text {
        @include favourites--text;
    
        max-width: 70%;
        margin: auto;
    
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 700px) {
    .m-favourites__text {
        max-width: 90%;
    }
}

@media screen and (max-width: 500px) {
    .m-favourites__headline {
        font-size: 18px;
        line-height: 24px;

        margin-bottom: 30px;
    }   

    .m-favourites__rectangle {
        margin-bottom: 30px;
    }

    .m-favourites-text {
        font-size: 12px;
    }
}

// Tab styles
// These styles are for the elements which are in the tabs of the popup window

 .m-favourites__tab-content {
    padding: 6em;
    padding-top: 2.5em;
}

@media screen and (max-width: 1100px) {    
    .m-favourites__tab-content {
        padding: 3em;
        padding-top: 1.5em;
    }
}

@media screen and (max-width: 850px) {    
    .m-favourites__tab-content {
        padding: 2em;
        padding-top: 1em;
    }
}

.m-favourites__tab-buttons {
    margin: auto;

    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 50px;
}

@media screen and (max-width: 700px) {    
    .m-favourites__tab-buttons {
        flex-direction: column;
    }
}

.m-favourites__tab-link {
    @include favourites--text;

    padding: 8px 12px 8px 12px;
    margin: 0 20px;

    color: black;

    text-transform: uppercase;

    border: 1px solid #000;
}

@media screen and (max-width: 700px) {
    .m-favourites__tab-link {
        margin: 10px 0;
    }
}

// This styles are for the "Musterkarten anfordern" field in tabs

.m-favourites__tab-content--sample-card {
    display: none;

    color: #333;
    text-align: left;

    margin: auto;
    padding: 0 30%;

    .form-row {
        margin: 0 !important;
    }
}

@media screen and (max-width: 1250px) {    
    .m-favourites__tab-content--sample-card {
        align-items: center;

        padding: 0 25%;
    }
}

@media screen and (max-width: 950px) {    
    .m-favourites__tab-content--sample-card {
        align-items: center;

        padding: 0 20%;
    }
}

@media screen and (max-width: 850px) {    
    .m-favourites__tab-content--sample-card {
        align-items: center;

        padding: 0 10%;
    }
}

// Form styles

.js-sample-form,
.js-share-form {
    // display: block needs to be added because at default it wasn't displayed
    .mod_form__row label {
        display: block;
    }

    // Change this styles to display form correctly
    .col {
        padding: 0 !important;
    }

    .mod_form__row {
        margin-bottom: 2.5rem;
    }

    @media screen and (max-width: 420px) {
        div[class^="mod_form__row__elem"] {

            margin-bottom: 2.5rem;
        }
    }
}

@media screen and (max-width: 420px) {
    .js-sample-form,
    .js-share-form {
        .mod_form__row {
            margin-bottom: 0;
        }
    }
}

// Change color of placeholer text in form

input::placeholder
 {
    color: #777;
}

textarea::-webkit-input-placeholder,
textarea:-moz-placeholder,
textarea::-moz-placeholder,
textarea:-ms-input-placeholder,
textarea::placeholder  {
    color: #777;
  }

  .m-favourites__input {
    @include favourites--text;

    height: 32px;
}

.m-favourites__input--textarea {
    @include favourites--text;
}

// Appearance auto to display dropdown arrow on select
select#exampleFormControlSelect1 {
    appearance: auto;
}

.m-favourites__checkbox-label {
    @include favourites--text;

    display: block;

    padding-left: 6px;
    
    color: #333;
    opacity: 1;
}

.m-favourites__checkbox {
    @include favourites--text;
    display: flex;
}

.m-favourites__checkbox.parsley-error {
    margin-bottom: 0;
}

.m-favourites__checkbox-input {
    padding: 4px;
    height: 15px;

    opacity: 1;
}

label.m-favourites__checkbox-label {
    margin-bottom: 0;
}

// Share favourites list

.m-share-list {
    height: auto;
    margin: auto;

    text-align: left;

    padding: 0 30%;

    display: none;
}

@media screen and (max-width: 1250px) {
    .m-share-list {
        padding: 0 25%;
    }
}

@media screen and (max-width: 800px) {
    .m-share-list {
        padding: 0 20%;
    }
}

@media screen and (max-width: 700px) {    
    .m-share-list {
        padding: 0 10%;
    }
}

.m-share-favourites {
    display: none;

    margin-bottom: 50px;
}

.m-share-favourites__headline {
    font-size: 24px;
    font-family: "Open Sans";
    font-weight: bold;
    line-height: 33px;

    color: #333;

    margin-top: 0;
    margin-bottom: 20px;
}

.m-share-favourites__text {
    font-size: 14px;
    font-family: "Open Sans";
    line-height: 19px;

    color: #333;

    margin-bottom: 20px;
}

.m-share-favourites__link {
    @include favourites--text;

    text-decoration: underline;

    margin-bottom: 50px;
}

.m-generate-share-link {
    padding: 8px;
    margin-bottom: 50px;
}

// Global styles for favourites buttons

 .m-favourites-button {
    display: inline-block;
    
    padding: 8px;
}

.m-favourites-button--active {
    @include favourites--text;
    text-transform: uppercase;

    background-color: #000;
    color: #fff;
}

// State button styles

.m-favourites-button--with-icon {
    @include favourites--text;

    display: inline-flex;
    align-items: center;

    text-transform: uppercase;

    img {
        width: 12px;
        height: 12px;

        padding: 0;
        margin-right: 5px;
    }

}

// This button is used to delete elements from favourites array
.js-delete-product-from-favourites {
    border: 1px solid #000;

	color: #333;

    margin-bottom: 0.75rem;
    padding: 7px 15px
}

@media screen and (max-width: 1000px) {
    .js-delete-product-from-favourites {
        font-size: 12px;

        padding: 7px 12px;
    }
}

// This button is used to get back to tabs of popup window, and is placed on "Musterkarten angefordert" popup window
.js-move-back-to-favourites {
    float: left;

    padding: 8px;
}

// This button is used to close popup window 
.js-close-popup-window {
    float: right;

    padding: 8px;
}

// This button gets displayed after "Musterkarten anfordern" got clicked
.js-contact-us-btn {
    margin-bottom: 400px;
}

// The following classes are used to change the look of .m-example__button on toggle

.noticed-favourite {
    display: none;
}

.icon-flag--white {
    display: none;
}

.state-noticed-favourite {
    .icon-flag {
        display: none;
    }

    .icon-flag--white {
        display: block;
    }

    .notice-favourite {
        display: none;
    }

    .noticed-favourite {
        display: block;
    }
}

// Icons 
.icon-flag {
    background-image: url(./assets/images/icon-fahne.svg);
    background-repeat: no-repeat;

    width: 14px;
    height: 14px;

    padding: 0;
    margin-right: 5px;
}

.icon-flag--white {
    background-image: url(./assets/images/icon-fahne-white.svg);
    background-repeat: no-repeat;

    width: 14px;
    height: 14px;

    padding: 0;
    margin-right: 5px;
}

.icon-back {
    background-image: url(./assets/images/icon-chevron-left.svg);
    background-repeat: no-repeat;

    width: 12px;
    height: 12px;

    padding: 0;
    margin-left: 5px;
}

.icon-close {
    background-image: url(./assets/images/icon-close.svg);
    background-repeat: no-repeat;

    width: 12px;
    height: 12px;

    padding: 0;
    margin-left: 5px;
}

// Delete bullet points in sidenav
.mm-listitem__text::before {
    display: none;
}