// Globals
@import 'global/reset';
@import 'global/variables';
@import 'global/functions';
@import 'global/mixins';
@import 'global/typography';
@import 'global/layout';
@import 'global/z-index';
@import 'global/helper';

// Vendor
@import './../node_modules/flickity/dist/flickity';
@import './../node_modules/jquery.mmenu/dist/jquery.mmenu.all';
@import './../assets/vendor-scripts/photoswipe/src/css/main';
$pswp__assets-path: '../assets/vendor-scripts/photoswipe/dist/default-skin/';
@import './../assets/vendor-scripts/photoswipe/src/css/default-skin/default-skin';
@import './../node_modules/snazzy-info-window/dist/snazzy-info-window.scss';

// -----------------------

// Boostrap Forms

// Deprication message

$enable-deprecation-messages: false;
$ignore-warning: true;

@import './../node_modules/bootstrap/scss/mixins/_deprecate.scss';


// Needed to import Forms
@import './../node_modules/bootstrap/scss/_functions.scss';
@import './../node_modules/bootstrap/scss/mixins/_breakpoints.scss';
@import './../node_modules/bootstrap/scss/mixins/_gradients.scss';
@import './../node_modules/bootstrap/scss/mixins/_transition.scss';
@import './../node_modules/bootstrap/scss/mixins/_border-radius.scss';
@import './../node_modules/bootstrap/scss/mixins/_box-shadow.scss';
@import './../node_modules/bootstrap/scss/mixins/_forms.scss';
@import './../node_modules/bootstrap/scss/vendor/_rfs.scss';

$border-radius: 0;

$primary:       #000;
$secondary:     #000;
$success:       #000;
$info:          #000;
$warning:       #000;
$danger:        #000;
$light:         #000;
$dark:          #000;

@import './../node_modules/bootstrap/scss/_variables.scss';

// Form
@import './../node_modules/bootstrap/scss/_forms.scss';

// Optional Button Styling
@import './../node_modules/bootstrap/scss/mixins/_hover.scss';
@import './../node_modules/bootstrap/scss/mixins/_buttons.scss';
@import './../node_modules/bootstrap/scss/_buttons.scss';

// -----------------------


// Modules with mixins to be included within Modules
@import 'modules/mod_grid';
@import 'modules/mod_ratio-image';
@import 'modules/mod_container';
@import 'modules/mod_text';

// Modules
@import 'modules/mod_styleguide';
@import 'modules/mod_header';
@import 'modules/mod_intro';
@import 'modules/mod_footer';
@import 'modules/mod_main';
@import 'modules/mod_teaser';
@import 'modules/mod_news-slider';
@import 'modules/mod_image';
@import 'modules/mod_cookie-warning';
@import 'modules/mod_image-text';
@import 'modules/mod_content';
@import 'modules/mod_contact';
@import 'modules/mod_example';
@import 'modules/mod_favourites';
@import 'modules/mod_photoswipe';
@import 'modules/mod_sidenav';
@import 'modules/mod_headline';
@import 'modules/mod_page-wrapper';
@import 'modules/mod_news-teaser';
@import 'modules/mod_arrow-link';
@import 'modules/mod_hr';
@import 'modules/mod_search-result';
@import 'modules/mod_form';
@import 'modules/mod_map';
@import 'modules/mod_test';
@import 'modules/mod_favourites';
@import 'modules/parsley';

.preload * {
	transition: none !important;
}

/**
 * We need this class for iOS. Since iOS load with the browser bar on bottom
 * we need the actual height of the visible vieport to calculate the height of
 * Intro slide.
 */

.js-initial-viewvport-height {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    pointer-events: none;
    transform: translate3d(0, 0, 0);
}

.loading-layer {
	position: fixed;

	top: 0;
	right: 0;
	bottom: 0;
	left: 0;

	background-color: rgba(#000, 0.5);

	display: flex;
	justify-content: center;
	align-items: center;

	opacity: 0;
	transition: opacity 0.3s ease-in-out;

	pointer-events: none;

	&.is-in {
		opacity: 1;
		transition: opacity 0.3s ease-in-out;
	}
}

.mod_favorites {
	position: fixed;

	top: 50px;
	right: 50px;
	bottom: 50px;
	left: 50px;

	padding: 50px;

	border: 1px solid #000;

	background-color: #fff;

	z-index: 1000;

	overflow: scroll;

	display: none;

	&.is-in {
		display: block;
	}

	.m-example.is-fav {
		display: block;

		img {
			opacity: 1;
		}
	}
}