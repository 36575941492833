.mod_footer {
    background-color: $base--color--gray--1;
    color: #fff;

    padding-top: space(2);
    padding-bottom: space(3);

    text-transform: uppercase;

    font-size: 12px;

    p {    
        line-height: 1.5;
        margin-bottom: space(2);
    }

    a {
        color: #fff;
    }
}

.mod_footer__container {
    position: relative;
    
    @include container(large);
}

.mod_footer__back-to-top {
    text-align: right;

    margin-bottom: space(2);
}

.mod_footer__main {
    margin-bottom: space(2);

    @media all and (min-width: $post-tablet--portrait) {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        .mod_footer__text {
            order: 1;
        }

        .mod_footer__logo-container {
            order: 2;
        }
    }
}

.mod_footer__logo {
    width: 150px;

    margin-right: space(5);

    margin-bottom: space(2);

    background-image: url('../assets/images/logo--escher--white.svg');
    background-size: contain;
    background-repeat: no-repeat;

    &:after {
        display: block;
        content: '';
        padding-top: calc(100% / (170/108));
    }
}

.mod_footer__bottom {
    @media all and (min-width: $post-tablet--portrait) {
        display: flex;
        justify-content: space-between;

        .mod_footer__copyright {
            order: 1;
        }

        .mod_footer__links {
            order: 2;
        }
    }
}

.mod_footer__links {
    @media all and (max-width: $tablet--portrait) {
        margin-bottom: space(2);
    }

    @media all and (min-width: $post-tablet--portrait) {
        display: flex;

        li:not(:last-child) {
            margin-right: space(1);
        }
    }
}

.mod_footer__back-to-top__icon {
    width: rem(21);
    height: rem(12);

    border-radius: 100%;

    cursor: pointer;

    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../assets/images/icon--backtotop.svg');

    margin-left: auto;
}