.m-news {
	background-color: $base--color--gray--4;

	padding: space(2) 0 space(4);

	@media all and (max-width: $phablet) {
		padding: space(2) 0 space(5);		
	}
}

.m-news__headline {
	text-align: center;
	margin-bottom: space(2);

	text-transform: uppercase;

	font-weight: 400;
}

.m-news__slider {
	@include container(large);

	padding: 0 space(5);
}
 
.m-news__slide {
	width: 100%;

	text-align: center;

	font-size: rem(30);

	padding: space(2) 0;

	@media all and (max-width: $phablet) {
		font-size: rem(20);
	}
}

// Buttons

.m-news {
	.flickity-button {
		width: space(3);
		height: space(3);
		border-radius: 0;
		background: transparent;

		display: flex;
		justify-content: center;

		&:active {
			opacity: 1;
		}

		&:after {
			content: '';

			width: space(3);
			height: space(3);
		
			background-repeat: no-repeat;
			background-position: center center;
			display: block;
		}

		&.previous {
			top: 50%;
			left: space(2);

			transform: translateY(-50%);

			&:after {
				background-image: url('../assets/images/icon--arrow--left.svg');
			}

			@media all and (max-width: $phablet) {
				left: space(3);
				top: 100%;
				transform: translateY(0);
				margin-top: space(3);
			}

			@media all and (max-width: $mobile) {
				left: space(2);
			}
		}
		
		&.next {
			top: 50%;
			right: space(2);

			transform: translateY(-50%);

			&:after {
				background-image: url('../assets/images/icon--arrow--right.svg');
			}

			@media all and (max-width: $phablet) {
				right: space(3);
				top: 100%;
				transform: translateY(0);

				margin-top: space(3);
			}

			@media all and (max-width: $mobile) {
				right: space(2);
			}
		}

		&:hover {
			background-color: transparent;
		}

		svg {
			display: none;
		}
	}

	.flickity-page-dots {
		right: 0;
		left: 0;

		.dot {
			border-radius: 0;
			width: 20px;
			height: 4px;

			margin: 0 4px;
		}
	}
}