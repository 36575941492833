/*
* global variables
*/

// Typography

$base--font-size: 16px;
$base--font-size--unitless: 16;
$base--line-height: 1.625;

// Colors

$base--color--main--1: #000;
$base--color--main--2: #fff;

$base--color--accent--1: #005195;
$base--color--accent--2: #AF1F6F;
$base--color--accent--3: #0D8E45;

$base--color--gray--1: #333333;
$base--color--gray--2: #666666;
$base--color--gray--3: #EAE9EA;
$base--color--gray--4: #F9F9F9;

// Spacer

// @todo reorder final values

$base--spacer: (
    1: 12,
    2: 24,
    3: 42,
    4: 60,
    5: 108,
    6: 180,
    7: 8
);

// Icons

$icons--margin-to-text: 8;

// Page max width

$base--page-max-width: 1180;

// Header

$base--header--height: 218;
$base--header--height--mobile: 218;

// Grid

$base--grid-gutter: 42; // Uses the values defined in $base--spacer
$base--grid-gutter--mobile: 24; // Uses the values defined in $base--spacer
$base--grid--max-page-width: $base--page-max-width;

// Misc

// Background color for images while loading
$base--global-transition-speed: 0.3s;
$base--img-bg-color: $base--color--gray--1;
$base--img-fade-in-speed: $base--global-transition-speed;
$base--color--text: $base--color--main--1;
$base--global-transition-speed: 0.3s;

/**
 * Breakpoints
 */

$viewport-increment: 1px;

$breakpoint--nav: 1020px; // Keep in sync with globals.js
$post-breakpoint--nav: 1021px;

// Desktop
$desktop: 1280px;
$post-desktop: $desktop + $viewport-increment;

// Tablet Landscape
$tablet--landscape: 1024px;
$post-tablet--landscape: $tablet--landscape + $viewport-increment;

// Tablet Portrait
$tablet--portrait: 900px;
$post-tablet--portrait: $tablet--portrait + $viewport-increment;

// Phablet
$phablet: 640px;
$post-phablet: $phablet + $viewport-increment;

// Mobile
$mobile: 420px;
$post-mobile: $mobile + $viewport-increment;

// Breakpoint debugging
body {
	.debug & {
		position: relative;

		&:after {
			display: block;
			position: fixed;
			content: 'After Desktop';

			right: 0;
			left: 0;
			bottom: 0;

			background-color: yellow;

			padding: 20px;
			text-align: center;
			color: #000;

			text-transform: uppercase;
			font-family: Arial;

			z-index: 9999;	

			@media all and (max-width: $desktop) {
				content: 'Desktop'
			}

			@media all and (max-width: $tablet--landscape) {
				content: 'Tablet Landscape'
			}

			@media all and (max-width: $tablet--portrait) {
				content: 'Tablet Portrait'
			}

			@media all and (max-width: $phablet) {
				content: 'Phablet'
			}

			@media all and (max-width: $mobile) {
				content: 'Mobile'
			}
		}
	}
}
