.m-headline {
	display: block;

	width: 100%;
}

.m-headline--h1 {
	> * {
		@include typo--h1;
	}
}

.m-headline--h2 {
	> * {
		@include typo--h2;	
	}
}