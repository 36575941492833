.m-arrow-link {
	@include typo--h3;

	&:after {
		content: '';
		position: relative;
		display: inline-block;

		width: rem(23);
		height: rem(9);

		margin-left: space(7);

		background-image: url('../assets/images/icon--arrow-forward.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}
}

.m-arrow-link--light {
	&:after {
		background-image: url('../assets/images/icon--arrow-forward--white.svg');
	}
}

.m-arrow-link--back {
	&:after {
		display: none;
	}

	&:before {
		content: '';
		position: relative;
		display: inline-block;

		width: rem(23);
		height: rem(9);

		margin-right: space(7);

		background-image: url('../assets/images/icon--arrow-backward.svg');
		background-size: contain;
		background-repeat: no-repeat;
	}
}