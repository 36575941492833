.m-image-text {
	@media all and (min-width: $post-tablet--portrait) {
		display: grid;

		grid-template-columns: minmax(auto, 500px) minmax(50%, 1fr);
		grid-column-gap: rem($base--grid-gutter);
	}
}

.m-image-text__image__container {
	@include ratio-image(1/1);
}

.m-image-text__image {
	@media all and (max-width: $tablet--portrait) {
		@include max-width(4);
		// @include center();
	}
}

.m-image-text__text {
	padding-top: rem($base--grid-gutter);

	.m-text {
		@include max-width(7);
	}
}

.m-image-text--inverted {
	grid-template-columns: minmax(50%, 1fr) minmax(auto, 500px);

	.m-image-text__image {
		order: 2;
	}

	.m-image-text__text {
		order: 1;

		margin-left: auto;
	}
}