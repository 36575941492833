@mixin font($weight: 400) {
	font-family: 'Open Sans', sans-serif;
	font-weight: $weight
}

html {
    font-size: $base--font-size;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    
    @include font();
}

body {
    -webkit-font-smoothing: antialiased;
    line-height: $base--line-height;
    color: $base--color--text;
}

// Headlines

@mixin typo--h1 {
	position: relative;

	font-size: 30px;
	text-transform: uppercase;
	text-align: center;	

	padding-bottom: space(3);
	margin-bottom: space(4);

	font-weight: 600;

	@media all and (max-width: $phablet) {
		font-size: 24px;
		line-height: 28px;

		padding-bottom: space(2);
	}

	&:after {
		display: block;
		content: '';

		position: absolute;
		top: 100%;

		height: 1px;
		width: 150px;

		background-color: #000;

		left: 50%;

		transform: translateX(-50%);
	}	
}

@mixin typo--h2 ($has-border: true) {
	position: relative;

	font-size: 30px;
	text-transform: uppercase;

	font-weight: 600;

	display: inline-block;

	margin-bottom: space(1);

	@if ($has-border) {
		margin-bottom: space(2);
		padding-bottom: space(1);
		border-bottom: 1px solid;
	}

	@media all and (max-width: $phablet) {
		font-size: 20px;
		line-height: 24px;
	}
}

@mixin typo--h3 () {
	margin-bottom: space(1);
	font-weight: 600;
	text-transform: uppercase;
}

@mixin typo--copy () {
	
}

@mixin font-weight-light {
	font-weight: 400;
}

@mixin font-weight-bold {
	font-weight: 600;
}

// Classes

.typo--h1 {
	@include typo--h1;
}

.typo--h2 {
	@include typo--h2;
}

.typo--h3 {
	@include typo--h3;
}	

/* General Text styles */

a {
	color: #000;
	text-decoration: none;
}

p a {
	@include font-weight-bold;
}

a[href^="tel:"] {
	text-decoration: none;
	border: none !important;
}

strong, b {
	@include font-weight-bold;
}

@mixin favourites--text {
	font-family: "Open Sans";
    font-size: 14px;
	line-height: 19px;
}