.m-cookie-warning {
	position: fixed;

	right: 0;
	bottom: 0;

	background-color: $base--color--main--1;

	padding: space(3, true);

	color: $base--color--main--2;

	z-index: 99999;

	display: none !important; // flex

	align-items: center;

	a {
		color: #fff;
		border-bottom: 1px solid #fff;
	}

	transform: translateY(100%);
	transition: transform 0.4s ease-in-out;

	&.is-in {
		transform: translateY(0);
		transition: transform 0.4s ease-in-out;
	}

	@media all and (max-width: $phablet) {
		left: 0;

		display: block;

		text-align: center;
	}
}

.m-cookie-warning__text {
	padding-right: space(5);

	@media all and (max-width: $phablet) {
		padding-right: 0;

		margin-bottom: space(2);
	}
}