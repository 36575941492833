/* Styles only applied to the `index.html` for testing purpose */

.m-styleguide__headline-toggle {
	position: fixed;

	top: 0;
	left: 0;

	width: 50px;
	height: 50px;

	z-index: 9999;

	cursor: pointer;
}

.m-module-header {
	background-color: #efefef;
	padding: space(1);

	color: $base--color--gray--2;

	margin-bottom: space(2);

	display: none;

	.styleguide-headlines-visible & {
		display: block;
	}
}

@mixin color-square {
	max-width: 500px;
	height: 100px;

	margin-bottom: space(2);
}

.base--color--main--1 {
	@include color-square;
	background-color: #000;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--main--1';
	}
}

.base--color--main--2 {
	@include color-square;
	background-color: #fff;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--main--2';
	}
}

.base--color--accent--1 {
	@include color-square;
	background-color: $base--color--accent--1;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--accent--1';
	}
}

.base--color--accent--2 {
	@include color-square;
	background-color: $base--color--accent--2;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--accent--2';
	}
}

.base--color--accent--3 {
	@include color-square;
	background-color: $base--color--accent--3;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--accent--3';
	}
}


.base--color--gray--1 {
	@include color-square;
	background-color: $base--color--gray--1;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--gray--1';
	}
}

.base--color--gray--2 {
	@include color-square;
	background-color:  #919191;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--gray--2';
	}
}

.base--color--gray--3 {
	@include color-square;
	background-color:  #bfbfbf;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--gray--3';
	}
}

.base--color--gray--4 {
	@include color-square;
	background-color:  #f5f5f5;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--gray--4';
	}
}

.base--color--gray--5 {
	@include color-square;
	background-color:  #464646;
	
	&:after {
		display: block;
		background-color: #fff;
		padding-bottom: 10px;
		color: #000;
		content: '$base--color--gray--5';
	}
}
