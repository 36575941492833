.m-news-teaser {
	display: block;

	position: relative;

	padding-bottom: space(3);
	margin-bottom: space(3);

	border-bottom: 1px solid $base--color--gray--3;

	.m-arrow-link {
		margin-top: space(2);
	}
}

.m-news-teaser__headline {
	@include typo--h2(false);
}

.m-news-teaser__text {
	@include mod_text();

	margin-bottom: space(2);
}

.m-news-teaser__grid-container {
	@media all and (min-width: $tablet--landscape) {
		display: grid;
		grid-template-columns: 1fr 1fr;

		grid-column-gap: rem($base--grid-gutter);
	}
}

.m-news-teaser--has-image {
	margin-bottom: space(3);

	.m-news-teaser__image {
		@media all and (max-width: $tablet--landscape) {
			@include max-width(4);

			margin-bottom: space(2);
		}
	}
}