.m-main {
	overflow: hidden;
}

.m-main--subpage {
	@include clearfix;

	background-color: $base--color--gray--4;

	padding: space(2) space(2) 0 space(2);

	@media all and (max-width: $tablet--landscape) {
		padding: 0;
	}
}