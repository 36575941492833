.test {
	overflow: hidden;
	
	img {
		width: 50%;

		float: left;
	}

	@media all and (max-width: $phablet) {
		img {
			width: 100%;

			&:last-child {
				display: none;
			}
		}
	}
}