.m-contact {
	display: grid;

	grid-template-columns: 25% 25% 25% 25%;

	padding-bottom: space(3);

	border-bottom: 1px solid $base--color--gray--3;

	margin-bottom: space(3);

	text-transform: uppercase;

	font-size: 14px;

	> div {
		padding-right: space(2);
	}

	@media all and (max-width: $tablet--portrait) {
		grid-template-columns: 100%;
	}
}

.m-contact--one-line {
	grid-template-columns: 0.75fr 2fr minmax(auto, 250px);

	@media all and (max-width: $tablet--portrait) {
		grid-template-columns: 100%;
	}
}

@mixin headline {
	@include font-weight-bold;

	font-size: 20px;
	line-height: 24px;

	margin-bottom: space(4);

	text-transform: uppercase;
}

.m-contact__headline {
	@include headline;
}

.m-contact__country {
	@include font-weight-bold;

	@media all and (max-width: $tablet--portrait) {
		margin-bottom: space(2);
	}
}

.m-contact__country-headline {
	@include headline();

	@media all and (max-width: $tablet--portrait) {
		margin-bottom: space(2);
	}

	margin-bottom: 0;
}

.m-contact__elem {
	span {
		display: block;
		margin-right: space(2);

		@media all and (max-width: $tablet--portrait) {
			display: block;
			margin-right: 0;
		}
	}

	@media all and (max-width: $tablet--portrait) {
		margin-bottom: space(2);
	}	
}

.m-contact__brands {
	display: flex;
	flex-wrap: wrap;
}

.m-contact__brand-item {
	flex: 0 0 33.33%;

	padding-right: space(2);

	&:last-child {
		margin-right: 0;
	}

	&:nth-child(n+4) {
		margin-top: space(2);
	}

	@media all and (max-width: $tablet--portrait) {
		max-width: rem(80);

		display: none;
	}

	opacity: 0;

	&.is-visible {
		opacity: 1;

		@media all and (max-width: $tablet--portrait) {
			display: block;
		}
	}
}

.m-contact__brand {
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center center;

	&:after {
	    display: block;
	    content: '';

	    padding-top: calc(100% / (180/110));
	}
}

.m-contact__contact {
	&:before {
		display: block;
		content: "\00a0\00a0";
	}

	@media all and (max-width: $tablet--portrait) {
		margin-bottom: space(2);
	}
}

.m-contact__elem--address {
	@media all and (min-width: $post-tablet--portrait) {
		&:before {
			display: block;
			content: "\00a0\00a0";
		}
	}
}