.m-search-result {
	position: relative;

	@include mod_text;

	padding: space(2) 0;

	&:after {
		content: '';
		display: block;
		width: 200vw;
		border-bottom: 1px solid $base--color--gray--3;

		position: absolute;
		bottom: 0;
		left: -100%;
	}

	&:first-child {
		&:before {
			content: '';
			display: block;
			width: 200vw;
			border-bottom: 1px solid $base--color--gray--3;

			position: absolute;
			top: 0;
			left: -100%;
		}
	}
}